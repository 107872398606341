var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"section bg-light",attrs:{"id":"features"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row align-items-center mb-5"},[_c('div',{staticClass:"col-md-5 order-2 order-md-1 mt-md-0 mt-5"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Perfect Solution For Small Businesses")]),_c('p',{staticClass:"text-muted mb-5"},[_vm._v(" Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis.. ")]),_c('a',{staticClass:"btn btn-primary",attrs:{"href":"javascript: void(0);"}},[_vm._v("Find out more "),_c('arrow-right-icon',{staticClass:"icon-xs ms-2"})],1)]),_vm._m(1)]),_c('div',{staticClass:"row align-items-center section pb-0"},[_vm._m(2),_c('div',{staticClass:"col-md-5 ms-md-auto"},[_c('h2',{staticClass:"mb-4"},[_vm._v(" Build community & conversion with our suite of social tool ")]),_c('p',{staticClass:"text-muted mb-5"},[_vm._v(" Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis.. ")]),_c('a',{staticClass:"btn btn-primary",attrs:{"href":"javascript: void(0);"}},[_vm._v("Find out more "),_c('arrow-right-icon',{staticClass:"icon-xs ms-2"})],1)])])])]),_c('section',{staticClass:"section bg-gradient-primary"},[_c('div',{staticClass:"bg-overlay-img",style:({
        'background-image':
          'url(' + require('@/assets/images/demos.png') + ')',
      })}),_vm._m(3)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center mb-5"},[_c('div',{staticClass:"col-lg-7 text-center"},[_c('h2',{staticClass:"fw-bold"},[_vm._v("Our Features")]),_c('p',{staticClass:"text-muted"},[_vm._v(" Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem ab illo inventore. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 ms-md-auto order-1 order-md-2"},[_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"ms-5 features-img"},[_c('img',{staticClass:"img-fluid d-block ms-auto rounded shadow",attrs:{"src":require("@/assets/images/features-1.jpg"),"alt":""}})]),_c('img',{staticClass:"dot-img-left",attrs:{"src":require("@/assets/images/dot-img.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"position-relative mb-md-0 mb-5"},[_c('div',{staticClass:"me-5 features-img"},[_c('img',{staticClass:"img-fluid d-block ms-auto rounded shadow",attrs:{"src":require("@/assets/images/features-2.jpg"),"alt":""}})]),_c('img',{staticClass:"dot-img-right",attrs:{"src":require("@/assets/images/dot-img.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"text-white mb-4"},[_vm._v("Build your dream website today")]),_c('p',{staticClass:"text-white mb-5 font-size-16"},[_vm._v(" Sed perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totamrem aperiam eaque inventore veritatis quasi. ")]),_c('a',{staticClass:"btn btn-lg btn-light",attrs:{"href":"#"}},[_vm._v("Ask for Demonstration")])])])])])
}]

export { render, staticRenderFns }