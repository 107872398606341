<script>
export default {
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <!-- Blog start -->
    <section class="section" id="blog">
      <div class="container">
        <div class="row justify-content-center mb-4">
          <div class="col-lg-7 text-center">
            <h2 class="fw-bold">Our Blog</h2>
            <p class="text-muted">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium totam rem ab illo inventore.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="card mt-4 border-0 shadow">
              <div class="card-body p-4">
                <span class="badge badge-soft-primary">UI & UX Design</span>
                <h4 class="font-size-22 my-4">
                  <a href="javascript: void(0);"
                    >Step bt step to conduct usability testing</a
                  >
                </h4>
                <p class="text-muted">
                  Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                  odit aut fugit.
                </p>
                <div class="d-flex align-items-center mt-4 pt-2">
                  <img
                    src="@/assets/images/user/img-2.jpg"
                    class="rounded-circle avatar-sm me-3"
                    alt="..."
                  />
                  <div class="flex-body">
                    <h5 class="font-size-17 mb-0">John Yeager</h5>
                    <p class="text-muted mb-0 font-size-14">
                      Designer, New York
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->

          <div class="col-lg-4">
            <div class="card mt-4 border-0 shadow">
              <div class="card-body p-4">
                <span class="badge badge-soft-primary">CEO</span>
                <h4 class="font-size-22 my-4">
                  <a href="javascript: void(0);"
                    >Increase conversion rate from ad to landing page</a
                  >
                </h4>
                <p class="text-muted">
                  Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                  odit aut fugit.
                </p>
                <div class="d-flex align-items-center mt-4 pt-2">
                  <img
                    src="@/assets/images/user/img-3.jpg"
                    class="rounded-circle avatar-sm me-3"
                    alt="..."
                  />
                  <div class="flex-body">
                    <h5 class="font-size-17 mb-0">Berneice Harris</h5>
                    <p class="text-muted mb-0 font-size-14">
                      Designer, New York
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->

          <div class="col-lg-4">
            <div class="card mt-4 border-0 shadow">
              <div class="card-body p-4">
                <span class="badge badge-soft-primary">Developer</span>
                <h4 class="font-size-22 my-4">
                  <a href="javascript: void(0);"
                    >Why small business should start marketing</a
                  >
                </h4>
                <p class="text-muted">
                  Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                  odit aut fugit.
                </p>
                <div class="d-flex align-items-center mt-4 pt-2">
                  <img
                    src="@/assets/images/user/img-1.jpg"
                    class="rounded-circle avatar-sm me-3"
                    alt="..."
                  />
                  <div class="flex-body">
                    <h5 class="font-size-17 mb-0">Sarah Pettway</h5>
                    <p class="text-muted mb-0 font-size-14">
                      Designer, New York
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- Blog end -->

    <!-- CTA start -->
    <section
      class="section bg-center w-100 bg-light"
      :style="{
        'background-image':
          'url(' + require('@/assets/images/cta-bg.png') + ')',
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card bg-gradient-primary text-center border-0">
              <div
                class="bg-overlay-img"
                :style="{
                  'background-image':
                    'url(' + require('@/assets/images/demos.png') + ')',
                }"
              ></div>
              <div class="card-body mx-auto p-sm-5 p-4">
                <div class="row justify-content-center">
                  <div class="col-lg-10">
                    <div class="p-3">
                      <h2 class="text-white mb-4">
                        Join our Growing Community
                      </h2>
                      <p class="text-white-70 font-size-16 mb-4 pb-3">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                      <a
                        href="javascript: void(0);"
                        class="btn btn-light rounded-pill"
                        >Sign Up for free</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- CTA end -->
  </div>
</template>